.table-tr-color {
    background-color: #f7f8fa;
}

.ant-table-title{
    padding: 0px;
    height: 50px;
    line-height: 50px;
    background-color: #f7f8fa;
    padding-left: 15px;
}

.ant-list-pagination {
    margin-top: 0px;
}